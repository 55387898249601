import { getParcelByIdOrNr, getParcelByXY, getParcelBySearch } from "./uldkApi.js";
import getAddressByXY from "./uugApi.js";

export default function interactiveMap() {
  const $showMapButtons = $(".js-show-interactive-map");
  const $interactiveMapContainer = $("#js-interactive-map-container");
  const $mapSearchContainer = $("#js-interactive-map-search-container");
  const addedLotsArray = [];
  let currentLotIndex = null;
  let cachedLotAddressResult = null;
  let addLotsByClickMode = true;
  let hideSearchPanel = false;

  const errorMessage = `<p class='interactive-map__error-message'>Wystąpił problem z wyszukaniem działki</p>`;
  const emptySearchMessage = `<p class='interactive-map__error-message'>Nie znaleziono żadnego pasującego wyniku</p>`;

  const warsawDistrictsCodes = {
    146502: "Bemowo",
    146503: "Białołęka",
    146504: "Bielany",
    146505: "Mokotów",
    146506: "Ochota",
    146507: "Praga-Południe",
    146508: "Praga-Północ",
    146509: "Rembertów",
    146510: "Śródmieście",
    146511: "Targówek",
    146512: "Ursus",
    146513: "Ursynów",
    146514: "Wawer",
    146515: "Wesoła",
    146516: "Wilanów",
    146517: "Włochy",
    146518: "Wola",
    146519: "Żoliborz",
  };

  const communeTypesCodes = {
    // original labels from TERYT
    // 1: "gmina miejska",
    // 2: "gmina wiejska",
    // 3: "gmina miejsko-wiejska",
    // 4: "miasto w gminie miejsko-wiejskiej",
    // 5: "obszar wiejski w gminie miejsko-wiejskiej",
    // 8: "dzielnice m. st. Warszawy",
    // 9: "delegatury w miastach: Kraków, Łódź, Poznań i Wrocław",
    // shorthand labels to display in form
    1: "miasto",
    2: "gmina",
    3: "gmina",
    4: "miasto",
    5: "gmina",
    8: warsawDistrictsCodes,
    9: "miasto",
  };

  const splitPlotUnit = function(plotId = "") {
    const warsawDistrictCommuneTypeNumber = "8";

    const [plotUnitId = ""] = plotId.split(".");
    const [
      plotUnitTeryt = "",
      plotUnitIdCommuneTypeNumber = "",
    ] = plotUnitId.split("_");
    const communeType = communeTypesCodes[plotUnitIdCommuneTypeNumber];

    if (!communeType) {
      return { plotUnitId, plotUnitIdCommuneLabel: "" };
    }

    const plotUnitIdCommuneLabel =
      plotUnitIdCommuneTypeNumber === warsawDistrictCommuneTypeNumber
        ? communeType[plotUnitTeryt]
        : communeType;

    return { plotUnitId, plotUnitIdCommuneLabel };
  };

  const loaderElement = `<div class="loader loader--small">Loading...</div>`;

  const lotIdToHtmlClass = function(lotId) {
    return lotId.replaceAll(".", "-").replaceAll("/", "-");
  };

  if (!$showMapButtons.length || !$mapSearchContainer.length) {
    return;
  }
  $mapSearchContainer.fadeIn();

  // adding map close button
  $mapSearchContainer.append(
    '<button class="js-show-added-lots-list interactive-map-show-lot-list-btn" type="button">pokaż dane dodanych działek</button>',
  );

  const $showAddedLotsList = $(".js-show-added-lots-list");

  if (!addedLotsArray.length) {
    $showAddedLotsList.hide();
  }
  // adding map close button
  $mapSearchContainer.append(
    '<button class="js-hide-interactive-map interactive-map-close-btn" type="button">&times;</button>',
  );
  // adding right panel
  $mapSearchContainer.append(`
    <div id="js-interactive-map-right-panel" class="interactive-map-right-panel">
      <button id="js-interactive-map-close-right-panel" class="interactive-map-close-btn right-panel-close-map-btn" type="button">&times;</button>
      <div id="js-interactive-map-right-panel-content" class="interactive-map-right-panel-content"></div>
    </div>
  `);
  const $rightPanel = $("#js-interactive-map-right-panel");
  // place search results etc here
  const $rightPanelContent = $("#js-interactive-map-right-panel-content");
  $rightPanelContent.hide();
  $rightPanel.fadeOut();

  function hideAndEmptyRightPanel() {
    $rightPanel.fadeOut();
  }

  $("#js-interactive-map-close-right-panel").on("click", function() {
    if (addedLotsArray.length) {
      $showAddedLotsList.show();
    }
    hideAndEmptyRightPanel();
  });

  const $hideMapButton = $(".js-hide-interactive-map");
  $hideMapButton.on("click", function() {
    $mapSearchContainer.fadeOut(400, function() {
      hideAndEmptyRightPanel();
    });
  });

  $(".js-show-added-lots-list").on("click", function() {
    $rightPanel.fadeIn();
  });

  const $multipleLotsToggle = $("#multiple-lots-toggle");
  $multipleLotsToggle.change(function() {
    addLotsByClickMode = this.checked;
  });

  const $searchPlotTabs = $mapSearchContainer.find(".search-plot__tab");
  const $searchPlotTabContainers = $mapSearchContainer.find(
    ".search-plot__tab-container",
  );
  $searchPlotTabContainers.hide();
  const activeTabContainerSelector = $searchPlotTabs
    .filter(".is-active")
    .data("tab-container");
  $(activeTabContainerSelector).fadeIn();

  $searchPlotTabs.on("click", function() {
    $searchPlotTabs.removeClass("is-active");
    const tabContainerSelector = $(this).data("tab-container");
    $searchPlotTabContainers.hide();
    $(this).addClass("is-active");
    $(tabContainerSelector).show();
  });

  const $toggleSearchPanelButton = $("#js-toggle-search-panel");
  const $allSearchPlotTabs = $(".js-search-plot__tabs");
  const $searchPlotForm = $(".js-search-plot__form");

  $toggleSearchPanelButton.on("click", function() {
    hideSearchPanel = !hideSearchPanel;
    if (hideSearchPanel) {
      $searchPlotForm.fadeOut();
      $allSearchPlotTabs.fadeOut();
      $(this).text("rozwiń panel");
      return;
    }

    $searchPlotForm.fadeIn();
    $allSearchPlotTabs.fadeIn();
    $(this).text("zwiń panel");
  });

  $showMapButtons.click(e => {
    currentLotIndex = e.target.dataset.lotIndex;
    $mapSearchContainer.fadeIn();
  });

  proj4.defs([
    [
      'EPSG:4326',
      '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
    [
      'EPSG:2180',
      '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs'
    
    ],
    [
      'EPSG:3857',
      '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs'
    ]
  ]);
  
  var crs2180 = new L.Proj.CRS(
    "EPSG:2180",
    "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs", {
      resolutions: [16934,8467,4233,2117,1058,529.16,264.32,132.245,52.91596562,26.45838625,13.22919312,5.2916,2.64585,1.058336, 0.529167725, 0.264583862, 0.132291931, 0.0661],
      origin: [100000.0, 850000.0],
      bounds: L.bounds ([-2861425.28, -1057534.86, 734796.39, 4087948.56])
    });
  
  const map = L.map('js-interactive-map-container', {
    minZoom: 3,
    maxZoom: 16,
    zoom: 12,
    center: [52.237049, 21.017532],
    continuousWorld: true,
    crs: crs2180,
    drawControl: true
  });

  var egib = L.tileLayer.wms('https://{s}.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow?', {
    subdomains: ['integracja01', 'integracja02'],
    layers: 'dzialki,numery_dzialek,budynki',
    tileSize: 1024,
    transparent: 'true',
    minZoom: 11,
    format: 'image/png',
    attribution: 'GUGiK',
  });
  
  var adresy = L.tileLayer.wms('https://mapy.geoportal.gov.pl/wss/ext/KrajowaIntegracjaNumeracjiAdresowej?',{
      layers: 'prg-place,prg-ulice,prg-adresy,prg-msc',
      transparent: 'true',
      minZoom: 13,
      format: 'image/png',
      tileSize: 1024,
      attribution: 'GUGiK',
  });
  
      
  var uzbrojenie = L.tileLayer.wms('https://integracja01.gugik.gov.pl/cgi-bin/KrajowaIntegracjaUzbrojeniaTerenu_14?', {
      layers: 'przewod_wodociagowy,przewod_gazowy,przewod_elektroenergetyczny',
      transparent: 'true',
      minZoom: 10,
      maxZoom: 16,
      format: 'image/png',
      tileSize: 1024,
      attribution: 'GUGiK',
      exceptions: 'application/vnd.ogc.se_xml'
  });

  var mpzp = L.tileLayer.wms('https://mapy.geoportal.gov.pl/wss/ext/KrajowaIntegracjaMiejscowychPlanowZagospodarowaniaPrzestrzennego?', {
      layers: 'granice,wektor-pkt,wektor-lin,wektor-pow,wektor-lzb,wektor-str,raster,plany_granice,plany',
      minZoom: 10,
      maxZoom: 16,
      opacity: 1,
      format: 'image/png',
      tileSize: 1024,
      transparent: 'true'
  });

  var bdot10k_wmts = L.tileLayer('https://mapy.geoportal.gov.pl/wss/service/WMTS/guest/wmts/G2_MOBILE_500?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topowebb&STYLE=default&TILEMATRIXSET=EPSG:2180&TILEMATRIX=EPSG:2180:{z}&TILEROW={y}&TILECOL={x}&FORMAT=image%2Fpng', {
    minZoom: 0    
  }).addTo(map); 

  var orto_wmts = L.tileLayer('https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMTS/StandardResolution?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=&STYLE=&FORMAT=image%2Fjpeg&TILEMATRIXSET=EPSG:2180&TILEMATRIX=EPSG:2180:{z}&TILEROW={y}&TILECOL={x}', {
    minZoom: 0
  });
  
  var baseMaps = { "Mapa topograficzna": bdot10k_wmts, "Ortofotomapa": orto_wmts };
  var services = {
      "Ewidencja gruntów i budynków": egib,
      "Adresy i ulice": adresy,
      "Uzbrojenie terenu": uzbrojenie,      
      "Miejscowe Plany Zagospodarowania Terenu": mpzp    
  };


  function _makeLowerCaseWords(str) {
    var arr = str.toLowerCase().split(' ');
    for (var i in arr) {
      const w = arr[i];
      arr[i] = w.charAt(0).toUpperCase() + w.slice(1);
    }
    return arr.join(' ');
  }

  
  function searchAddresses(text, callResponse) {
    return $.post({
        url: 'https://services.gugik.gov.pl/uug/index.php',
        data: {
          request: 'GetAddress',
          location: text,
          srid: 4326, 
          exact_number: 1,
          accuracy: 0.6
        },
        success: function (data) {
          if ((data.results) && (Object.keys(data.results).length)) {
            var result = [];
            for (var i in data.results) {
              var w = data.results[i];
              var adr = (w.city ? w.city : '') + (w.street ? ", " + w.street.replace('ulica ','') : '') + (w.number ? " " + w.number : '');
              var dodatkowe = "";               
              if (w.jednostka) {
                var darr =  w.jednostka.replace('{', '').replace('}', '').replace('Polska,', '').split(',');
                dodatkowe = [...new Set(darr) ].join(', ');
              }
              if (w.road) {
                adr = "droga: " + w.road + ", km: " + w.marker;
                dodatkowe = "";
              }
              if (w.description) {
                dodatkowe = w.description;
              }              
              if (w.name) {
                adr = w.name;
                var darr = [ w.type, w.voivodeship, w.county, w.commune ];
                dodatkowe = [...new Set(darr) ].join(', ');
              }
              result.push({
                loc: [ parseFloat(w.y), parseFloat(w.x) ],
                title: adr + (dodatkowe.length ? ', ' + dodatkowe : '')
              }); 
            }
            callResponse(result);
          } else {
            $.getJSON('https://nominatim.openstreetmap.org/search?format=json&countrycodes=pl&q=' + text, function (data) {
              var result = [];
              for (var i in data) {
                var w = data[i];
                result.push({
                  loc: [ parseFloat(w.lat), parseFloat(w.lon) ],
                  title: w.display_name
                });
              }
              callResponse(result);          
            });
          }
        }
      });
  }

  async function searchParcels(text, callResponse) {
    const { count, results } = await getParcelBySearch(text.trim());        

    if (count) {
      const result = results[0];
      let { plotGeom } = result;
      plotGeom = plotGeom
        .replace("SRID=4326;", "")
        .replace("POLYGON((", "")
        .replace("))", "");

      var lotAddressDetails = await getAddressByXY(plotGeom, "POLYGON");

      if (lotAddressDetails.results) {
        cachedLotAddressResult = lotAddressDetails.results["1"];
        if ((cachedLotAddressResult) && ('street' in cachedLotAddressResult) && (cachedLotAddressResult.street))
          cachedLotAddressResult.street = cachedLotAddressResult.street.replace('ulica ', '');
      } else {
        cachedLotAddressResult = null;
      }
      handleShowPlot(result, { isPlotSelected: true });
    } else {
      $.notify('Brak wyników', 'error');
    }
  
    callResponse([]);
  }

  async function searchParcelsNr(_text, callResponse) {
    var text = $('#leaflet-search-parcelobr').val().trim() + ' ' + _text.trim();
    const { count, results } = await getParcelBySearch(text);

    if (count) {
      var result = [];
      
      for (var i in results) {
        var parc = results[i];
        var plotGeom = parc.plotGeom
          .replace("SRID=4326;", "")
          .replace("POLYGON((", "")
          .replace("))", "")
          .split(",")
          .shift();
        var parr = plotGeom.split(' ');
        try {
          result.push({
            loc: [ parseFloat(parr[0]), parseFloat(parr[1]) ],
            title: $('#leaflet-search-parcelobr').val() + ', ' + _text + ', ' + parc.plotId
          });
        } catch (e) {}
      }

      callResponse(result);
    } else {    
      $.notify('Brak wyników', 'error');
      callResponse([]);
    }
  }
  
  async function selectParcel(text) {
    const { count, results } = await getParcelBySearch(text.trim());        

    if (count) {
      const result = results[0];
      let { plotGeom } = result;
      plotGeom = plotGeom
        .replace("SRID=4326;", "")
        .replace("POLYGON((", "")
        .replace("))", "");

      var lotAddressDetails = await getAddressByXY(plotGeom, "POLYGON");

      if (lotAddressDetails.results) {
        cachedLotAddressResult = lotAddressDetails.results["1"];
        if ((cachedLotAddressResult) && ('street' in cachedLotAddressResult) && (cachedLotAddressResult.street))
          cachedLotAddressResult.street = cachedLotAddressResult.street.replace('ulica ', '');
      } else {
        cachedLotAddressResult = null;
      }
      
      handleShowPlot(result, { isPlotSelected: true });
    }
  }

  var controlSearchAddress = new L.Control.Search({
    sourceData: function (text, callResponse) {
      return searchAddresses(text, callResponse);
    },
    filterData: function (text, records) {
      return records;
    },
    container: 'leaflet-search-address',
    autoResize: false,
    autoCollapse: false,
    collapsed: false,
    initial: false,
    autoType: false,
    textCancel: 'Rezygnuj',
    textPlaceholder: 'np. Marki, Andersa 1',
    textErr: 'Niczego nie znaleziono',
    minLength: 3
  });
  map.addControl(controlSearchAddress);

  var controlSearchIdent = new L.Control.Search({
    sourceData: function (text, callResponse) {
      return searchParcels(text, callResponse);      
    },
    filterData: function (text, records) {
      return records;
    },
    container: 'leaflet-search-ident',
    autoResize: false,
    autoCollapse: false,
    collapsed: false,
    initial: false,
    autoType: false,
    textCancel: 'Rezygnuj',
    textPlaceholder: 'Wpisz identyfikator działki',
    textErr: 'Niczego nie znaleziono',
    minLength: 3
  });
  map.addControl(controlSearchIdent);
  
  var controlSearchParcel = new L.Control.Search({
    sourceData: function (text, callResponse) {
      return searchParcelsNr(text, callResponse);      
    },
    filterData: function (text, records) {
      return records;
    },
    moveToLocation: function (latlng, title, map) {
      var tarr = title.split(',');
      $('#leaflet-search-parcel .search-input').not('#leaflet-search-parcelobr').val(tarr[1].trim());
      selectParcel(tarr[2].trim());
    },
    container: 'leaflet-search-parcel',
    autoResize: false,
    autoCollapse: false,
    collapsed: false,
    initial: false,
    autoType: false,
    textCancel: 'Rezygnuj',
    textPlaceholder: 'Wpisz numer działki',
    textErr: 'Niczego nie znaleziono',
    minLength: 1
  }).on('search:cancel', function () { $('#leaflet-search-parcelobr').val('') });
  map.addControl(controlSearchParcel);

  $('#leaflet-search-parcel .leaflet-control-search').prepend('<input class="search-input" type="text" value="" id="leaflet-search-parcelobr" placeholder="Wpisz obręb" style="margin-right: 5px" />');
  
  map.on('zoomend', function() {
    var zoomlevel = map.getZoom();
    if (zoomlevel < 10) {
      if (map.hasLayer(egib))
        map.removeLayer(egib);
      if (map.hasLayer(adresy))
        map.removeLayer(adresy);
    } else {
      if (!map.hasLayer(egib)) {
        map.addLayer(egib);
      }
      if (!map.hasLayer(adresy)) {
        map.addLayer(adresy);
      }
    }
  });

  L.control.scale({ position: 'bottomleft' }).addTo(map);
  map.zoomControl.setPosition("bottomleft");
  L.control.layers(baseMaps, services, { position: 'bottomleft' }).addTo(map);

  var drawnItems = new L.FeatureGroup();
  map.addLayer(drawnItems);
  var drawControl = new L.Control.Draw({
    position: 'bottomright',
    draw: {
      circlemarker: false
    },
    edit: {
      featureGroup: drawnItems
    }
  });
  map.addControl(drawControl);
  
  var imgExporter = L.control.bigImage({
    position: 'bottomright',
    printControlLabel: '<i class="fa-solid fa-download"></i>',
    title: 'Pobierz obraz',
  });
  imgExporter._click = function (e) {
    this._print();
  };
  imgExporter.addTo(map);
  
  map.on('draw:created', function (e) {
    var type = e.layerType,
    layer = e.layer;
    drawnItems.addLayer(layer);
  });

  map.setMaxBounds([
    [55.2223, 13.717078],
    [48.904122, 24.584035],
  ]);
  
  map.on("click", addLotByClickOnMap);
  
  $rightPanelContent.fadeIn();

  const clickingOnMap = {
    enable() {
      map.on("click", addLotByClickOnMap);
    },
    disable() {
      map.off("click", addLotByClickOnMap);
    },
  };
  
  map.on('draw:drawstart draw:editstart draw:deletestart', function () {
    clickingOnMap.disable();
  });
  
  map.on('draw:drawstop draw:editstop draw:deletestop', function () {
    setTimeout(function() {
      clickingOnMap.enable();
    }, 500);
  });

  async function addLotByClickOnMap(e) {
    if (!addLotsByClickMode) {
      return;
    }

    $("#address-search-tab-container")
      .find(".interactive-map__error-message")
      .remove();

    $interactiveMapContainer.css("cursor", "wait");

    const { lat, lng } = e.latlng;
    const [lngToULDK, latToULDK] = proj4("EPSG:4326", "EPSG:2180", [lng, lat]);

    try {
      const { results: uldkResults } = await getParcelByXY({
        x: lngToULDK,
        y: latToULDK,
      });
      
      const responseAddressFromXY = await getAddressByXY(
        { x: lng, y: lat },
        "POINT",
      );

      let addressFromXY = null;

      if (responseAddressFromXY.results) {
        addressFromXY = responseAddressFromXY.results["1"];
      }
     
      if (uldkResults.length) {
        cachedLotAddressResult = addressFromXY;
        if ((cachedLotAddressResult) && ('street' in cachedLotAddressResult) && (cachedLotAddressResult.street))
          cachedLotAddressResult.street = cachedLotAddressResult.street.replace('ulica ', '');
        handleShowPlot(uldkResults[0]);
      } else {
        cachedLotAddressResult = null;
      }
    } catch (error) {
      console.error(error);
      $("#address-search-tab-container").append(errorMessage);
    } finally {
      $interactiveMapContainer.css("cursor", "default");
    }
  }

  $mapSearchContainer.hide();

  const [
    $cityInput,
    $streetInput,
    $homeNumberInput,
    $idInput,
    $precinctInput,
    $plotNumberInput,
  ] = [
    $searchPlotTabContainers.find("#search-plot__city-input"),
    $searchPlotTabContainers.find("#search-plot__street-input"),
    $searchPlotTabContainers.find("#search-plot__home-input"),
    $searchPlotTabContainers.find("#search-plot__id-input"),
    $searchPlotTabContainers.find("#search-plot__precinct-input"),
    $searchPlotTabContainers.find("#search-plot__number-input"),
  ];

  const [$idSearchButton, $addressSearchButton] = [
    $searchPlotTabContainers.find("#js-search-id"),
    $searchPlotTabContainers.find("#js-search-address"),
  ];

  let selectedPolygon;
  // function from https://uldk.gugik.gov.pl/
  function showPlotOutline(geom, plotId = "") {

    const plotIdClassName = lotIdToHtmlClass(plotId);
    const isLotNew = !addedLotsArray.some(lot => {
      return lot.plotId === plotId;
    });
    const gPoints = new Array();
    const _p = geom.split(",");

    let p0 = null;
    let xMin = 1000,
      yMin = 1000,
      xMax = -1000,
      yMax = -1000;
    for (let i = 0; i < _p.length; i++) {
      const point = _p[i].split(" ");
      if (p0 == null) p0 = point;
      xMin = Math.min(xMin, point[0]);
      xMax = Math.max(xMax, point[0]);
      yMin = Math.min(yMin, point[1]);
      yMax = Math.max(yMax, point[1]);
      gPoints.push(new Array(point[1], point[0]));
    }

    if (selectedPolygon) selectedPolygon.remove();

    if (isLotNew) {
      const newLot = L.polygon([gPoints], {
        className: plotIdClassName,
      });
      newLot.addTo(map);
    }

    selectedPolygon = L
      .polygon([gPoints], {
        color: "yellow",
        className: plotIdClassName,
      })
      .addTo(map);

    const $addedLot = $(document.getElementsByClassName(plotIdClassName));

    $addedLot.each(function() {
      $(this).attr("plotId", plotIdClassName);
    });

    let timer = 0;
    const delay = 300;
    let prevent = false;

    $addedLot
      .on("click", function() {
        clickingOnMap.disable();
        const plotId = $(this).attr("plotId");
        timer = setTimeout(function() {
          if (!prevent) {
            $(`.js-lot-button-${plotId}`).trigger("click");
          }
          prevent = false;
          clickingOnMap.enable();
        }, delay);
      })
      .on("dblclick", function() {
        clickingOnMap.disable();
        const plotId = $(this).attr("plotId");
        clearTimeout(timer);
        prevent = true;
        $(`.js-lot-delete-button-${plotId}`).trigger("click");
        setTimeout(function() {
          clickingOnMap.enable();
        }, 500);
      });

    const bounds = L.latLngBounds(
      L.latLng(yMin, xMin),
      L.latLng(yMax, xMax),
    );
    map.fitBounds(bounds, { animate: false });
  }

  $idSearchButton.on("click", async function() {
    await handleSearchByIdOrNr();
  });

  $addressSearchButton.on("click", async function() {
    await handleSearchByAddress();
  });



  async function handleSearchByIdOrNr() {
    $rightPanelContent.empty();
    const plotId = $idInput.val();
    const precinct = $precinctInput.val();
    const plotNumber = $plotNumberInput.val();
    const $idTabContainer = $("#plot-id-search-tab-container");

    $idSearchButton.text("");
    $idSearchButton.append(loaderElement);

    $idTabContainer.find(".interactive-map__error-message").remove();

    // TODO CHECK IF COUNT GIVES CORRECT NUMBER

    const { count, results } = await getParcelByIdOrNr({
      id: plotId,
      precinct,
      number: plotNumber,
    });
    
    if (count) {
      if (count > 1) {
        console.log("more than one result", { count, results });
      } else {
        const result = results[0];
        let { plotGeom } = result;
        plotGeom = plotGeom
          .replace("SRID=4326;", "")
          .replace("POLYGON((", "")
          .replace("))", "");                
          
        var lotAddressDetails = await getAddressByXY(plotGeom, "POLYGON");

        if (lotAddressDetails.results) {
          cachedLotAddressResult = lotAddressDetails.results["1"];
        } else {
          cachedLotAddressResult = null;          
        }
        handleShowPlot(result, { isPlotSelected: true });
      }
    } else {
      $idTabContainer.append(emptySearchMessage);
      console.log({ count, msg: "no results" });
      $.notify('Brak wyników', 'error');
    }
    $idSearchButton.find(".loader").remove();
    $idSearchButton.text("szukaj");
  }

  function handleShowPlot({
    plotId = "",
    plotVoivodeship = "",
    plotCounty = "",
    plotCommune = "",
    plotStreet = "",
    plotNumber = "",
    plotCity = "",
    plotPostalCode = "",
    plotPrecinct = "",
    plotGeom = "",
    plotRegistrationUnit = "",
  } = {}) {
    // piece of code from https://uldk.gugik.gov.pl/
    // I suppose it's needed to paint plot properly
    const parsedGeom = plotGeom
      .replace("SRID=4326;", "")
      .replace("POLYGON((", "")      
      .replace("))", "")
      .replaceAll("\r", "")
      .split('),(')
      .shift();

    showPlotOutline(parsedGeom, plotId);
    showPlotResults({
      plotId,
      plotVoivodeship,
      plotCounty,
      plotCommune,
      plotStreet,
      plotNumber,
      plotCity,
      plotPostalCode,
      plotPrecinct,
      plotGeom,
      plotRegistrationUnit,
    });
  }

  function handleFillFormWithCurrentSearch() {
    const fillFormWithLotData = function(el, lot) {
      const {
        plotId = "",
        plotVoivodeship = "",
        plotCounty = "",
        plotCommune = "",
        plotNumber = "",
        plotPrecinct = "",
        cachedLotAddressResult = {},
      } = lot;
      const $el = $(el);
      $el.find("input[name$=-voivodeship]").val(plotVoivodeship);
      $el.find("input[name$=-powiat]").val(plotCounty);
      $el.find("input[name$=-municipality]").val(plotCommune);
      if (cachedLotAddressResult) {
        $el.find("input[name$=-street]").val(_makeLowerCaseWords(cachedLotAddressResult.street));
        $el.find("input[name$=-house_no]").val(cachedLotAddressResult.number);
        $el.find("input[name$=-postal_code]").val(cachedLotAddressResult.code);
        $el.find("input[name$=-city]").val(_makeLowerCaseWords(cachedLotAddressResult.city));
      } else {
        $el.find("input[name$=-street]").val('');
        $el.find("input[name$=-house_no]").val('');
        $el.find("input[name$=-postal_code]").val('');
        $el.find("input[name$=-city]").val('');
      }
      $el.find("input[name$=-registration_unit]").val(plotId);
      $el.find("input[name$=-registration_precit]").val(plotPrecinct);
      $el.find("input[name$=-registration_lot_no]").val(plotNumber);
      // TODO
      // $el.find("input[name$=-post]");
    };
       
    if (typeof(__simpleLotFillFieldClass) != 'undefined') {

      const $currentForm = $(`#form-${currentLotIndex}-item`);

      var ids = [];
      addedLotsArray.forEach(function(lot, index) {
        ids.push(lot.plotId);
      });

      $currentForm.find('.' + __simpleLotFillFieldClass + ' input[type="text"]').val(ids.join(', '));
      $currentForm.find('.' + __simpleLotFillFieldClass + ' input[type="search"]').val(ids.join(', '));

      $mapSearchContainer.hide();
      hideAndEmptyRightPanel();
      // Empty lots array
      addedLotsArray.splice(0, addedLotsArray.length);
      
    } else {

      try {
        addedLotsArray.forEach(function(lot, index) {
          if (index > 0) {
            $("#add-form-item").trigger("click");
          }
  
          const $currentForm =
            index === 0
              ? $(`#form-${currentLotIndex}-item`)
              : $(".lot-form-item").last();
          fillFormWithLotData($currentForm, lot);
        });
          
        $mapSearchContainer.hide();
        hideAndEmptyRightPanel();
        // Empty lots array
        addedLotsArray.splice(0, addedLotsArray.length);
      } catch (e) {
        console.error(e);
        $.notify('Błąd pobierania danych działek. Wpisz dane działek ręcznie albo spróbuj później. Przepraszamy za niedogodności.',
          { className: 'error', position: 'bottom right' }
        );
      }
    }
  }
  
  function showPlotResults({
    plotId = "",
    plotVoivodeship = "",
    plotCounty = "",
    plotCommune = "",
    plotStreet = "",
    plotNumber = "",
    plotCity = "",
    plotPostalCode = "",
    plotPrecinct = "",
    plotGeom = "",
    plotRegistrationUnit = "",
  } = {}) {
    if (
      !addedLotsArray.some(lot => {
        return lot.plotId === plotId;
      })
    ) {
      addedLotsArray.push({
        plotId,
        plotVoivodeship,
        plotCounty,
        plotCommune,
        plotStreet,
        plotNumber,
        plotCity,
        plotPostalCode,
        plotPrecinct,
        cachedLotAddressResult,
        plotGeom,
        plotRegistrationUnit,
      });
    }

    const splittedPlotUnit = splitPlotUnit(plotId);

    const plotUnitId = splittedPlotUnit.plotUnitId;

    
    $rightPanelContent.empty();
    $rightPanel.fadeIn();
    
    if (cachedLotAddressResult) {
      $rightPanelContent.append(
        ` <div id="js-interactive-map-plot-results" class="interactive-map-plot-results">
            <p class="interactive-map-plot-info">
              Województwo: ${plotVoivodeship}
            </p>
            <p class="interactive-map-plot-info">
              Powiat: ${plotCounty}
            </p>
            <p class="interactive-map-plot-info">
              Gmina: ${plotCommune}
            </p>
            <p class="interactive-map-plot-info">
              Miejscowość: ${cachedLotAddressResult.city}
            </p>
            <p class="interactive-map-plot-info">
              Ulica: ${
                cachedLotAddressResult.street ? cachedLotAddressResult.street : ""
              } 
            </p>
            <p class="interactive-map-plot-info">
              Numer: ${
                cachedLotAddressResult.number ? cachedLotAddressResult.number : ""
              }
            </p>
            <p class="interactive-map-plot-info">
              Kod pocztowy: ${
                cachedLotAddressResult.code ? cachedLotAddressResult.code : ""
              }
            </p>
            <p class="interactive-map-plot-info">
              Identyfikator: ${plotId}
            </p>
            <p class="interactive-map-plot-info">
              Obręb ewidencyjny: ${plotPrecinct}
            </p>
            <p class="interactive-map-plot-info interactive-map-lot-info--bold">
              Numer działki ewidencyjnej: ${plotNumber}
            </p>
            <div class="interactive-map-plot-info__control-buttons">
              <button type="button" id="js-add-lot" class="interactive-map-plot-info__control-button form-button--light form-button--text-small half-width-button">Wyszukaj kolejną działkę</button>  
              <button type="button" id="js-add-lot-and-exit" class="interactive-map-plot-info__control-button form-button form-button--text-small half-width-button">Zapisz i wróć do wniosku</button>  
            </div>
            </div>
          <div class="added-lots__container">
            <div class="added-lots__title">Dodane działki:</div>
            <div class="added-lots__list-container">
              <div class="added-lots__list"></div>
            </div>
          </div>
        `,
      );
    } else {
      $rightPanelContent.append(
        ` <div id="js-interactive-map-plot-results" class="interactive-map-plot-results">
            <p class="interactive-map-plot-info">
              Województwo: ${plotVoivodeship}
            </p>
            <p class="interactive-map-plot-info">
              Powiat: ${plotCounty}
            </p>
            <p class="interactive-map-plot-info">
              Gmina: ${plotCommune}
            </p>
            <p class="interactive-map-plot-info">
              Brak adresu
            </p>           
            <p class="interactive-map-plot-info">
              Identyfikator: ${plotId}
            </p>
            <p class="interactive-map-plot-info">
              Obręb ewidencyjny: ${plotPrecinct}
            </p>
            <p class="interactive-map-plot-info interactive-map-lot-info--bold">
              Numer działki ewidencyjnej: ${plotNumber}
            </p>
            <div class="interactive-map-plot-info__control-buttons">
              <button type="button" id="js-add-lot" class="interactive-map-plot-info__control-button form-button--light form-button--text-small half-width-button">Wyszukaj kolejną działkę</button>  
              <button type="button" id="js-add-lot-and-exit" class="interactive-map-plot-info__control-button form-button form-button--text-small half-width-button">Zapisz i wróć do wniosku</button>  
            </div>
            </div>
          <div class="added-lots__container">
            <div class="added-lots__title">Dodane działki:</div>
            <div class="added-lots__list-container">
              <div class="added-lots__list"></div>
            </div>
          </div>
        `,
      );
    }
    const $rightPanelAddedLotsList = $rightPanel.find(".added-lots__list");
    addedLotsArray.forEach(lot => {
      const lotIdClassName = lotIdToHtmlClass(lot.plotId);
      const splittedLotId = lot.plotId.split(".");
      const lotNumber = lot.plotId;
      $rightPanelAddedLotsList.prepend(
        `
      <div class="added-lots__lot">
        <button type="button" class="js-lot-details added-lots__lot-details-button js-lot-button-${lotIdClassName}" data-lot-details='${JSON.stringify(
          lot,
        )}'>Działka: ${lotNumber}</button>
        <button type="button" class="js-lot-list-remove lot-list-remove js-lot-delete-button-${lotIdClassName}" data-lot-id="${
          lot.plotId
        }">&times;</button>
      </div>
      `,
      );
    });

    $rightPanelAddedLotsList.find(".js-lot-details").on("click", function() {
      const lotData = $(this).data().lotDetails;
      cachedLotAddressResult = lotData.cachedLotAddressResult;
      handleShowPlot(lotData, { isPlotSelected: true });
    });

    $rightPanelAddedLotsList
      .find(".js-lot-list-remove")
      .on("click", function(e) {
        e.preventDefault();
        const lotIdToRemove = e.target.dataset.lotId;
        const lotIndexToRemove = addedLotsArray.findIndex(
          lot => lot.plotId === lotIdToRemove,
        );
        addedLotsArray.splice(lotIndexToRemove, 1);
        const lotClassNameOnMap = lotIdToHtmlClass(lotIdToRemove);
        $(`.${lotClassNameOnMap}`)
          .fadeOut()
          .remove();
        $(this)
          .parent()
          .fadeOut(300, function() {
            $(this).remove();
          });
        $.notify('Działka ' + lotIdToRemove + ' została usunięta', 'error');
      });

    $rightPanel.find("#js-add-lot").on("click", function() {
      $rightPanel.fadeOut(400, function() {
        $searchPlotTabContainers.each(function() {
          $(this)
            .find("input")
            .val("");
        });
        $cityInput.focus();
      });
    });

    $rightPanelContent.find("#js-add-lot-and-exit").on("click", function() {
      handleFillFormWithCurrentSearch({
        plotUnitId,
        plotVoivodeship,
        plotCounty,
        plotCommune,
        plotStreet,
        plotNumber,
        plotCity,
        plotPostalCode,
        plotPrecinct,
        plotRegistrationUnit,
      });
    });
    
    $.notify('Działka ' + plotId + ' została dodana', 'success');
  }

  // Return a function that handles opening map on an added lot form
  return function(newLotIndex) {
    currentLotIndex = newLotIndex;
    $mapSearchContainer.show();
  };
}
